var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"combo-select",class:{fixed: _vm.fixed}},[_vm._t("toggler",[(!_vm.fixed)?_c('input-group',{attrs:{"clearable":_vm.isClearable,"error":_vm.error,"open":_vm.open},on:{"clear":_vm.clear},scopedSlots:_vm._u([(!_vm.disabled)?{key:"append",fn:function(){return [_c('span',{staticClass:"input-group-text",on:{"click":_vm.toggle}},[_c('i',{staticClass:"fas fa-angle-down"})])]},proxy:true}:null],null,true)},[_c('div',{staticClass:"toggler",class:{disabled: _vm.disabled},on:{"click":_vm.toggle}},[(_vm.required)?_c('i',{staticClass:"fa fa-asterisk"}):_vm._e(),(_vm.label)?_c('label',{staticClass:"form-label",class:{up: _vm.up}},[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),(_vm.valueText)?_c('span',{staticClass:"form-value"},[_vm._v(_vm._s(_vm.valueText))]):_vm._e()])]):_vm._e()],{"toggle":_vm.toggle}),(_vm.open || _vm.focus || _vm.fixed || _vm.mouseInSelection)?_c('div',{staticClass:"selection",on:{"mouseenter":function($event){_vm.mouseInSelection = true},"mouseleave":function($event){_vm.mouseInSelection = false}}},[_vm._t("selection",[_c('input-autocomplete',{staticClass:"autocomplete",class:{ ac: _vm.floatOverInput },attrs:{"auto-focus":true,"dataKey":_vm.dataKey,"fixed":true,"label":_vm.searchPlaceholder,"limit-key":_vm.limitKey,"numberOfSuggestions":_vm.numberOfSuggestions,"only-use-options":_vm.onlyUseOptions,"options":_vm.options,"client-id":_vm.clientId,"query":_vm.query,"required":_vm.required,"searchableFields":_vm.searchableFields,"timeout":_vm.timeout,"variables":_vm.variables,"error":_vm.error,"tab-index":_vm.tabIndex},on:{"blur":_vm.autocompleteBlur,"enter":_vm.enterPressed,"focus":_vm.autocompleteFocus,"input":_vm.input,"itemSelected":_vm.itemSelected,"search":_vm.searchChanged,"tab":_vm.tabPressed},scopedSlots:_vm._u([{key:"beforeSuggestions",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [_vm._t("beforeSuggestions",null,{"isSelected":isSelected,"select":select,"search":search})]}},('suggestions' in _vm.$scopedSlots)?{key:"suggestions",fn:function(ref){
var items = ref.items;
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [_vm._t("suggestions",null,{"items":items,"isSelected":isSelected,"select":select,"search":search})]}}:{key:"suggestion",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_vm._t("suggestion",null,{"item":item,"selected":selected})]}}],null,true)})],{"select":_vm.itemSelected})],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }