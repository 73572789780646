


































































import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {InputGroup, InputAutocomplete},
})
export default class InputComboSelect extends Mixins(InputMixin) {
  @Prop(String) protected label?: string;
  @Prop(String) protected text!: string;

  @Prop(String) protected clientId?: string;
  @Prop(Object) protected query!: any;
  @Prop(String) protected dataKey!: string;

  @Prop(String) protected searchPlaceholder?: string;
  @Prop({type: Array, default: () => []}) protected searchableFields!: string[];
  @Prop({type: String, default: 'limit'}) protected limitKey!: string;
  @Prop({type: Number, default: 5}) protected numberOfSuggestions!: string[];
  @Prop({type: Number, default: 500}) protected timeout!: number;

  @Prop({type: Boolean, default: false}) protected onlyUseOptions!: boolean;
  @Prop({type: Boolean, default: false}) protected allowFreeText!: boolean;
  @Prop({type: Boolean, default: false}) protected floatOverInput!: boolean;
  @Prop({type: Array, default: () => []}) protected options!: any[];
  @Prop({type: Object, default: () => ({})}) protected variables!: any;

  @Prop(String) protected indexKey!: string;
  @Prop({type: [String, Function]}) protected valueKey!: any;
  @Prop({type: Boolean, default: false}) protected fixed!: boolean;

  private mouseInSelection: boolean = false;

  private valueText: string = '';
  private search: string = '';
  private open: boolean = false;

  protected clear() {
    this.itemSelected(null);
    this.$emit('cleared');
  }

  @Watch('focus')
  private onFocusChanged() {
    if (this.focus) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }

  @Watch('text', {immediate: true})
  private onTextChanged(text: string | null) {
    if (typeof text === 'string' && text.length > 0) {
      this.valueText = text;
    } else if (text === null) {
      this.valueText = '';
    }
  }

  @Watch('valueText')
  @Emit('textChange')
  private onvValueTextChanged(text: string) {
    return text;
  }

  private searchChanged(search: string) {
    this.search = search;
  }

  private tabPressed() {
    this.mouseInSelection = false;
    this.focus = false;
    this.open = false;
  }

  private enterPressed(text: string) {
    this.$emit('validateSearchEmail', text);

    if (!this.allowFreeText) {
      return;
    }

    this.value = text;
    this.valueText = text;
    this.$emit('change', text && text.length > 0 ? text : null);
    this.$emit('itemSelected', text);
    this.mouseInSelection = false;
    this.focus = false;
    this.open = false;
  }

  private autocompleteFocus() {
    this.focus = true;
  }

  private autocompleteBlur() {
    this.focus = false;
    this.close();
  }

  private toggle() {
    if (!this.disabled) {
      this.open = !this.open;
    }
  }

  private close() {
    this.open = false;
  }

  private itemSelected(item: any) {
    if (item) {
      this.valueText = (typeof this.valueKey === 'function')
        ? this.valueKey(item)
        : item[this.valueKey];

      this.$emit('change', item[this.indexKey] || item);
    } else {
      this.valueText = '';
      this.$emit('change', null);
    }

    this.$emit('itemSelected', item);
    this.mouseInSelection = false;
    this.close();
  }
}

