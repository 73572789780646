





































































import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {InputText, InputGroup, Spinner},
})
export default class InputAutocomplete extends Mixins(InputMixin) {
  @Prop(String) protected clientId?: string;
  @Prop(Object) protected query!: any;
  @Prop(String) protected dataKey!: string;
  @Prop(String) protected valueKey?: string;
  @Prop({type: Object, default: () => ({})}) protected variables!: any;
  @Prop({type: String}) protected text?: string;

  @Prop({type: Array, default: () => []}) protected searchableFields!: string[];
  @Prop({type: Number, default: 5}) protected numberOfSuggestions!: string[];
  @Prop({type: Number, default: 500}) protected timeout!: number;

  @Prop({type: Boolean, default: false}) protected onlyUseOptions!: boolean;
  @Prop({type: Array, default: () => []}) protected options!: any[];

  @Prop({type: Boolean, default: true}) protected searchIcon!: boolean;
  @Prop({type: Boolean, default: false}) protected visibleOnFocus!: boolean;
  @Prop({type: Boolean, default: false}) protected fixed!: boolean;

  private mouseInSuggestions: boolean = false;

  private search: string | null = '';
  private searchQuery: string = '';

  private loading: boolean = false;
  private timeoutCallback?: any;

  private selectedItem: any = null;

  get open() {
    return !this.visibleOnFocus || this.focus || this.mouseInSuggestions;
  }

  get queryVariables() {
    return {
      ...this.variables,
      limit: this.numberOfSuggestions,
      search: this.searchQuery,
      searchableFields: this.searchableFields,
    };
  }

  get labelText(): string {
    if (this.label) {
      return this.label;
    } else if (this.searchableFields.length > 0) {
      return this.searchableFields.join(', ');
    }

    return this.$it('global.search', 'Search') as string;
  }

  private mounted() {
    if (this.autoFocus) {
      (this.$refs.input as InputText).becomeFirstResponder();
    }
  }

  private resolveData(data: any) {
    data = data[this.dataKey];

    if ('data' in data) {
      data = data.data;
    }

    return data;
  }

  private isSelected(item: any): boolean {
    if (typeof item === 'string') {
      return (this.selectedItem !== null && item === this.selectedItem);
    }

    return (this.selectedItem !== null && item.id === this.selectedItem.id);
  }

  private setIsLoading(isLoading: boolean): boolean {
    this.loading = isLoading;

    return isLoading;
  }

  private tabPressed() {
    this.$emit('tab');
  }

  private enterPressed() {
    this.$emit('enter', this.search);
  }

  private activate() {
    this.focus = true;
  }

  private inactivate() {
    this.$nextTick(() => {
      this.focus = false;
    });
  }

  @Watch('text', {immediate: true})
  private onTextChanged(text: string) {
    if (text && text.length > 0) {
      this.search = text;
    }
  }

  @Watch('model', {immediate: true})
  private onModelChanged() {
    if (typeof this.model === 'string') {
      this.search = this.model;
    } else if (this.model === null) {
      this.search = '';
    }
  }

  @Watch('focus')
  private onFocusChanged() {
    this.searchQuery = this.search || '';

    if (this.focus) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }

  @Watch('search')
  private onSearchChange(value: string) {
    clearTimeout(this.timeoutCallback);

    this.$emit('search', value);
    this.select(null);

    this.timeoutCallback = setTimeout(() => {
      this.searchQuery = value;
    }, this.timeout);

    if (this.valueKey) {
      this.$emit('change', value);
    }

    return value;
  }

  private select(item: any) {
    this.selectedItem = item;

    this.onSelect(this.selectedItem);
  }

  private onSelect(selectedItem: any) {
    const value = (selectedItem && this.valueKey)
      ? selectedItem[this.valueKey]
      : selectedItem;

    if (this.valueKey === undefined) {
      this.$emit('change', value); // WARNING: I don't think this does anything (the whole undefined clause)
    } else if (typeof value === 'string') {
      this.search = value;
      this.$emit('input', value);
    }

    if (selectedItem) {
      this.$emit('itemSelected', selectedItem);
    }

    this.mouseInSuggestions = false;
  }
}
